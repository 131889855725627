import './App.css';
import $ from 'jquery';
import 'jquery.terminal';
import 'jquery.terminal/css/jquery.terminal.css';
import {useEffect} from "react";

function App() {
    useEffect(() => {
        $('body').terminal("https://vpn.enot.dev/auth.php", {
            login: true,
            greetings: "#!wjfs",
            completion: true,
            processRPCResponse: function(data) {
                if (typeof data === 'string' || data instanceof Array) {
                    this.echo(data);
                } else if (data && typeof data === 'object') {
                    var str = Object.keys(data).map(function(key) {
                        return '[[b;#fff;]' + key + ']: ' + data[key];
                    }).join('\n');
                    this.echo(str);
                }
            }
        });
    }, []);
    return (<div className="App"></div>);
}

export default App;
